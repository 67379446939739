import React from 'react'
import './style.css'
import { HashLink } from 'react-router-hash-link';


const Welcome = () =>{
    return(
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>Welcome to our big day</h2>
                            <p>We are so excited to share our wedding day with you. You’ll be able to find all the details you need here, from venue information to RSVP instructions. We can’t wait to make some special memories with you that will last a lifetime.</p>
                            <div className="btn"><HashLink smooth to="/home/#rsvp">RSVP</HashLink></div>
                            <div className="btn"><HashLink smooth to="/home/#event">Location</HashLink></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Welcome;