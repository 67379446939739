import React from 'react'
import Sectiontitle from '../section-title'
import strory1 from '../../images/events/house.jpg'

import './style.css'

const Location = () => {
    return(

        
    <div id="event" className="service-area section-padding">
        <div className="container">
            <Sectiontitle section={'When & Where'}/>
            <div className="service-area-menu">
                <div className="Ceremony-wrap">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ceromony-img">
                                <img src={strory1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ceromony-content">
                                <h3>Wedding Ceremony & Reception</h3>
                                <span>Saturday, 06 July 2024, 12:30 PM-11.00 PM</span>
                                <span>With Canapés and the Reception to follow.</span>
                                <span>Woodlands Estate</span>
                                <span>42 Whitikahu Road, Taupiri</span>
                                <p>Parking provided follow the signs.</p>
                                <a target='_blank'
                                    rel='noopener noreferrer' 
                                    href="https://www.woodlands.co.nz/">See Location</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Location;