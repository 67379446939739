import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'


class Gift extends Component {
    render() {
      return (
          <div className="gift-area">
              <div className="container">
                    <div className="col-12">
                      <div className="section-title text-center">
                          <h2>Gift Registry</h2>
                          <p>We do not expect gifts.</p>
                          <p>However, if you wish to give a gift, below is a link to a list of gift suggestions.</p>
                          <p>There will also be a wishing well at the reception.</p>
                          <p></p>
                          <div className="btn"><a target='_blank'
                            rel='noopener noreferrer' 
                            href="https://www.easyregistry.co.nz/Registry/Gifts/thomasanddaniella">Registry</a>
                          </div>
                        
                      </div>
                  </div>
                  <div className="row gift-item">
                  </div>
              </div>
          </div>
      );
    }
  }

export default Gift;